<template>
<div>
  <div class="container-fluid">
    <PageTitle :noAdd="true" />

    <b-tabs v-if="isList">
      <b-tab :title="pageTitle">
        <div 
          class="card"
        >
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <h5 class="card-title">{{pageTitle}} List</h5>
            </div>
            <div class="col-md-9">
              <div class="row justify-content-end">
                <div class="col-md-5 ml-auto mb-2">
                  <form @submit.prevent="doFilter()">
                    <div class="form-group mb-0">
                      <div class="input-group">
                        <input 
                          v-model="filter.search"
                          type="text" 
                          class="form-control" 
                          placeholder="Search"
                        />
                        <div class="input-group-append">
                          <button class="btn btn-info" type="submit"><i class="fas fa-search"></i></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-12 col-sm-auto col-md-auto">
                  <button class="btn btn-info btn-block" type="button" @click="doReset">Reset</button>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="table-responsive">
            <table class="table product-overview table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
                <tr v-for="(v,k) in data.data" :key="k">
                  <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                  
                  <td>{{ v.al_title }}</td>

                  <td>
                    <router-link 
                      v-if="moduleRole('Edit')"
                      :to="{name:$route.name, params:{pageSlug:v[idKey]}}"  
                      class="text-inverse icon_action act_icon" v-tooltip="'Edit'">
                      <i class="ti-marker-alt"></i>
                    </router-link> 
                  </td>
                </tr>
                <tr v-if="notFound"><td colspan="99" class="text-center"><h4>{{notFound}}</h4></td></tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <div class="pagination-flat float-right" v-if="data.data!==false&&data.data.length">
                  <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                    <span slot="prev-nav">Previous</span>
                    <span slot="next-nav">Next</span>
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <template v-for="(vlang,klang) in lang">
        <b-tab :title="`Page Settings - ${vlang.text}`" :key="klang">
          <bo-card title="Hero Image" footer-tag="footer">
            <b-row class="gutter-2">
              <b-col md="3">
                <bo-card-img
                  :src="uploader(((content['hero_' + vlang.value] || {}).value || {}).img)"
                  :title="((content['hero_' + vlang.value] || {}).value || {}).title"
                  :isTitleHtml="true"
                >
                  <template #buttons>
                    <b-button
                      v-if="moduleRole('Edit')"
                      variant="secondary"
                      size="sm"
                      pill
                      @click="editHeroImage(content['hero_' + vlang.value])"
                      v-b-tooltip.hover="'Edit'"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                  </template>
                </bo-card-img>
              </b-col>
            </b-row>
          </bo-card>
        </b-tab>
      </template>
      <template v-for="(vlangseo,klangseo) in lang">
        <b-tab :title="`SEO Settings - ${vlangseo.text}`" :key="`seo_${klangseo}`">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-10">
                  <h5 class="card-title">SEO Settings</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <ValidationObserver 
                    v-slot="{ handleSubmit }" 
                    :ref="`VFormSeo${vlangseo.value}`"
                  >
                    <form
                      @submit.prevent="
                        handleSubmit(
                          updateContent(
                            'seo-setting',
                            content['seo_' + vlangseo.value],
                            `VFormSeo${vlangseo.value}`,
                            false,
                            true
                          )
                        )
                      "
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12 col-md-8">
                              <div class="form-group">
                                <label :for="`seoTitle${vlangseo.value}`">
                                  Title
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <template v-if="editFormId == `seo${vlangseo.value}`">
                                  <b-form-input
                                    :id="`seoTitle${vlangseo.value}`"
                                    v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                    :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                  ></b-form-input>
                                  <VValidate
                                    :vid="`seoTitle${vlangseo.value}`"
                                    name="Title"
                                    v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                    rules="required|min:3|max:200"
                                  />
                                </template>
                                <template v-else>
                                  <p class="font-weight-bold">{{ ((content['seo_' + vlangseo.value] || {}).value || {}).title }}</p>
                                </template>
                              </div>
                            </div>
                            <div class="col-12 col-md-8">
                              <div class="form-group">
                                <label>
                                  Description
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <template v-if="editFormId == `seo${vlangseo.value}`">
                                  <b-form-textarea
                                    :id="`seoDescription${vlangseo.value}`"
                                    v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                    :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    rows="5"
                                  ></b-form-textarea>
                                  <VValidate
                                    :vid="`seoDescription${vlangseo.value}`"
                                    name="Description"
                                    v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                    rules="required|min:10|max:500"
                                  />
                                </template>
                                <template v-else>
                                  <div v-html="((content['seo_' + vlangseo.value] || {}).value || {}).description"></div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="moduleRole('Edit')">
                        <div class="col-12">
                          <hr />
                          <div class="text-right">
                            <template v-if="editFormId == `seo${vlangseo.value}`">
                              <button
                                type="button"
                                @click="editFormId = ''"
                                class="btn btn-rounded btn-light mr-2"
                              >
                                Cancel
                              </button>
                              <button type="submit" class="btn btn-rounded btn-primary">
                                Save
                              </button>
                            </template>
                            <template v-else>
                              <button
                                type="button"
                                @click="editFormId = `seo${vlangseo.value}`"
                                class="btn btn-rounded btn-primary"
                              >
                                Ubah
                              </button>
                            </template>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </template>
    </b-tabs>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Add':'Edit'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">
                    Title 1
                    <span class="text-danger mr5">*</span>
                  </label>
                  <input 
                    v-model="row.al_title"
                    type="text" 
                    class="form-control" 
                    placeholder="Title 1" 
                  /> 
                  <VValidate name="Title 1" v-model="row.al_title" :rules="mrValidation.al_title" />
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">
                    Description 1
                    <span class="text-danger mr5">*</span>
                  </label>
                  <ckeditor
                    class="editor"
                    :editor="editor"
                    v-model="row.al_description.description_1"
                    :config="editorConfig"
                  ></ckeditor>
                  <VValidate name="Description 1" v-model="row.al_description.description_1" rules="required" />
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">
                    Title 2
                    <span class="text-danger mr5">*</span>
                  </label>
                  <input 
                    v-model="row.al_description.title_2"
                    type="text" 
                    class="form-control" 
                    placeholder="Title 2" 
                  /> 
                  <VValidate name="Title 2" v-model="row.al_description.title_2" rules="required" />
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">
                    Description 2
                    <span class="text-danger mr5">*</span>
                  </label>
                  <ckeditor
                    class="editor"
                    :editor="editor"
                    v-model="row.al_description.description_2"
                    :config="editorConfig"
                  ></ckeditor>
                  <VValidate name="Description 2" v-model="row.al_description.description_2" rules="required" />
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">
                    Title 3
                    <span class="text-danger mr5">*</span>
                  </label>
                  <input 
                    v-model="row.al_description.title_3"
                    type="text" 
                    class="form-control" 
                    placeholder="Title 3" 
                  /> 
                  <VValidate name="Title 3" v-model="row.al_description.title_3" rules="required" />
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">
                    Description 3
                    <span class="text-danger mr5">*</span>
                  </label>
                  <ckeditor
                    class="editor"
                    :editor="editor"
                    v-model="row.al_description.description_3"
                    :config="editorConfig"
                  ></ckeditor>
                  <VValidate name="Description 3" v-model="row.al_description.description_3" rules="required" />
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">
                    Title 4
                    <span class="text-danger mr5">*</span>
                  </label>
                  <input 
                    v-model="row.al_description.title_4"
                    type="text" 
                    class="form-control" 
                    placeholder="Title 4" 
                  /> 
                  <VValidate name="Title 4" v-model="row.al_description.title_4" rules="required" />
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">
                    Description 4
                    <span class="text-danger mr5">*</span>
                  </label>
                  <ckeditor
                    class="editor"
                    :editor="editor"
                    v-model="row.al_description.description_4"
                    :config="editorConfig"
                  ></ckeditor>
                  <VValidate name="Description 4" v-model="row.al_description.description_4" rules="required" />
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">
                    Title 5
                    <span class="text-danger mr5">*</span>
                  </label>
                  <input 
                    v-model="row.al_description.title_5"
                    type="text" 
                    class="form-control" 
                    placeholder="Title 5" 
                  /> 
                  <VValidate name="Title 5" v-model="row.al_description.title_5" rules="required" />
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">
                    Description 5
                    <span class="text-danger mr5">*</span>
                  </label>
                  <ckeditor
                    class="editor"
                    :editor="editor"
                    v-model="row.al_description.description_5"
                    :config="editorConfig"
                  ></ckeditor>
                  <VValidate name="Description 5" v-model="row.al_description.description_5" rules="required" />
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12 text-right">
                <router-link :to="{ name: $route.name }" class="btn btn-rounded btn-light mr-2">Cancel</router-link>
                <button type="submit" class="btn btn-rounded btn-info">{{isAdd ? "Submit" : "Update"}}</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

    <ValidationObserver v-slot="{ handleSubmit }" ref="VFormHeroImage">
        <b-modal
          id="heroImageModal"
          size="md"
          title="Edit Hero Image"
          @ok.prevent="
            handleSubmit(
              updateContent(
                'hero-image',
                inputHeroImage,
                'VFormHeroImage',
                'heroImageModal'
              )
            )
          "
        >
          <template #default>
            <div class="infoHTML"></div>
            <b-form 
              @submit.prevent="
                  handleSubmit(
                    updateContent(
                      'hero-image',
                      inputHeroImage,
                      'VFormHeroImage'
                    )
                  )
                "
            >
              <b-row>
                <b-col md="12">
                  <BoField 
                    label="Sub Title"
                    placeholder="e.g. PRIVACY POLICY"
                    v-model="((inputHeroImage || {}).value || {}).sub_title"
                    :rules="mrValidation.sub_title"
                  />
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Title"
                    placeholder="e.g. Jakarta Running Festival 2024"
                    v-model="((inputHeroImage || {}).value || {}).title"
                    :rules="mrValidation.title"
                  />
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_image"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img"
                      :rules="mrValidation.hero_image.img"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Small Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="((inputHeroImage || {}).value || {}).fallback_img_small"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_small"
                      label="Image"
                      @data="v => ((inputHeroImage || {}).value || {}).img_small = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="((inputHeroImage || {}).value || {}).img_small"
                      :rules="mrValidation.hero_image.img_small"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Alt Image"
                    placeholder="e.g. Hero Image"
                    v-model="((inputHeroImage || {}).value || {}).alt_img"
                    :rules="mrValidation.alt_img"
                  />
                </b-col>
                <hr />
              </b-row>
            </b-form>
          </template>

          <template #modal-footer="{ ok, cancel }">
            <b-button variant="outline-secondary" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="info" @click="ok()">
              Submit
            </b-button>
          </template>
        </b-modal>
      </ValidationObserver>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";
import assign from "lodash/assign";

import BoCard from '@/components/BoCard.vue'
import BoCardImg from '@/components/BoCardImg.vue'
// import StaticSeoAtom from "@/views/atoms/global/StaticSeoAtom.vue";

export default{
  extends:GlobalVue,
  components:{
    PageTitle, 
    ckeditor: CKEditor.component,
    BoCard,
    BoCardImg
    // StaticSeoAtom
  },
  data(){
    return {
      lang: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        }
      ],
      staticSeo: {},
      idKey:'al_id',
      editor: ClassicEditor,
      editorConfig: {
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link"],
        },
      },
      content: [],
      editFormId: "",
      inputHeroImage: {}
    }
  },
  methods: {
    updateContent(type, input, IDFORM, modalId = false, usingKey = false) {
      this.doSubmit(
        "/do/" + this.modulePage,
        assign(
          {
            type,
          },
          {},
          {
            as_value: input,
          }
        ),
        (type) => {
          if (type === "success") {
            this.apiGet();
            this.editFormId = "";
            if (modalId) {
              this.$bvModal.hide(modalId);
            }
          }
        },
        "POST",
        IDFORM,
        usingKey
      );
    },

    // Hero Image
    editHeroImage(data) {
      this.inputHeroImage = {
        ...data
      }

      this.$bvModal.show('heroImageModal')
    },
  },
  mounted(){
    this.apiGet()

    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>